@media (min-width: map-get($breakpoints, lg) + 1px) {
  .menuMain {
    border-bottom: 5px solid #B7DB57;
  }
}

.pageFooter { // Simplified footer
  display: block;
  padding: 3em 0;
  ul.socialIcons{
    margin-bottom:20px;
    display: none;
  }
  .Footercredits{
    text-align: center;
  }
  ul.footerLinks li{
    display: inline-block;
    margin: 0 20px 20px 0;
  }
}

// Donation thank you page - hide back to home page

.donationThankYouWrapper .donationShare a.cta-button {
  display: none;
}